import { useEffect, useRef, useState } from 'react';
import { screenContext } from './modules/context/screenContext';
import { refContext } from './modules/context/refContext';
import { contactFormContext } from './modules/context/contactFormContext';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';

// Importing React routes legacy
import RootRouter from './navigation/RootRouter';

function App() {

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: 'poppins'
      },
    },
  });

  const [ openForm, setOpenForm ] = useState<boolean>(false);
  const [ screenWidth, setScreenWidth ] = useState<number>(window.innerWidth);
  const [ screenHeight, setScreenHeight ] = useState<number>(window.innerHeight);

  const maxVerticalTabletScreen = 1024;
  const maxVerticalMobileScreen = 500;
  const maxHeight = 700;

  const refTechnology = useRef(null);
  const refBenefits = useRef(null);
  const refFocus = useRef(null);
  const refModal = useRef(null);

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <contactFormContext.Provider value ={{
        openForm,
        setOpenForm
      }}>
        <refContext.Provider value = {{
          refTechnology,
          refBenefits,
          refFocus,
          refModal
        }}>
          <screenContext.Provider value= {{
            screenWidth,
            maxVerticalTabletScreen,
            maxVerticalMobileScreen,
            setScreenWidth,
            screenHeight, 
            setScreenHeight,
            maxHeight
          }}>
            {/* <Home /> */}
            <BrowserRouter>
              <RootRouter/>
            </BrowserRouter>
          </screenContext.Provider>
        </refContext.Provider>
      </contactFormContext.Provider>
    </ThemeProvider>
  );
}

export default App;
