import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import SuspenseScreen from "../modules/screens/SuspenseScreen";

const HomeScreen = React.lazy(
    () => import("../modules/screens/Home")
);

const TermScreen = React.lazy(
    () => import("../modules/screens/TermsAndConditions")
);

const NotFoundScreen = React.lazy(
    () => import("../modules/screens/NotFound")
);

const UnsubscribeScreen = React.lazy(
    () => import("../modules/screens/UnsubscribeScreen")
);

const RootRouter = () => {

    return(
        <Routes>
            <Route 
                path="/"
                element={
                    <Suspense fallback={<SuspenseScreen/>}>
                        <HomeScreen/>
                    </Suspense>
                }
            />
            <Route 
                path="inicio"
                element={
                    <Suspense fallback={<SuspenseScreen/>}>
                        <HomeScreen/>
                    </Suspense>
                }
            />
            <Route 
                path="inactivarme"
                element={
                    <Suspense fallback={<SuspenseScreen/>}>
                        <UnsubscribeScreen/>
                    </Suspense>
                }
            />
            <Route 
                path="terminosycondiciones"
                element={
                    <Suspense fallback={<SuspenseScreen/>}>
                        <TermScreen/>
                    </Suspense>
                }
            />
            <Route 
                path="*"
                element={<NotFoundScreen/>}
            />
        </Routes>
    );

}

export default RootRouter;