import { Box } from "@mui/system"

const SuspenseLoading = () => {
    return(
        <Box sx={styles.suspenseContainer}>
            <img width={100} height={100} src="/images/heartbeat.gif" alt="" />
        </Box>
    );
}

const styles = {
    suspenseContainer: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white'
    }
}

export default SuspenseLoading;